import React from "react";
import { InputAdornment } from "@material-ui/core";
import {
  SimpleTextField,
  DateTimeField,
  Head,
  VisitorTypes,
  Toast,
  SimpleNRICField,
} from "components";
import SubmitButton from "components/submitButton";
import { Calender } from "assets/icons";
import { CountryPhoneField, CustomFormControl } from "components/formField";
import Asynchronous from "components/autoComplete";

const styles = {
  input: {
    width: 406,
    marginBottom: 15,
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
    color: "#404040",
  },
  heading: {
    fontSize: 22,
    fontFamily: "Montserrat",
    color: "#000",
  },
  types: {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
  },
};

const CalendarIcon = () => {
  return (
    <InputAdornment position="end">
      <Calender />
    </InputAdornment>
  );
};

const InvitePage = ({
  data,
  validation,
  inviteSubmit,
  disableBtn,
  handleInviteeChange,
  handleInviteCustomChange,
  validationErrors,
  handleVisitingTime,
  handleChangeNric,
  numPlateChange,
  access_card,
}) => {
  const {
    name,
    phone,
    country_code,
    employer,
    visitor_type,
    // visiting_time,
    // remarks,
    fin_number,
    accessCard,
    number_plate,
    expire_at,
  } = data;

  return (
    <React.Fragment>
      <Head subHeading={"Contractor Registration"} />
      <div className="main-container">
        <div style={{ textAlign: "center", width: "100%" }}>
          <p id="head-text" style={{ fontWeight: 500, fontSize: 20 }}>
            Enter the contractor details
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            inviteSubmit();
          }}
          className="forminvite"
        >
          <div style={styles.types}>
            <VisitorTypes visitor_type={visitor_type} />
          </div>
          <div style={{ paddingTop: "30px" }}>
            <div style={{ marginBottom: 0 }}>
              {/* <p style={styles.heading}>Contractor Details</p> */}
              <SimpleTextField
                required
                id="name"
                name="name"
                label="Name"
                value={name}
                onChange={handleInviteeChange}
                helperText=" "
                autoComplete="off"
              />

              <CountryPhoneField
                selected={country_code}
                onSelect={handleInviteeChange}
                required
                name="phone"
                label="Phone"
                value={phone}
                onChange={handleInviteCustomChange}
                error={validation.phone}
              />

              <SimpleNRICField
                label="NRIC/FIN (Last 4 digits)"
                name="fin_number"
                id="fin_number"
                required
                value={fin_number}
                onChange={handleChangeNric}
                error={validation.fin_number}
                autoComplete="off"
              />
              <SimpleTextField
                id="number_plate"
                name="number_plate"
                label="Number Plate"
                value={number_plate}
                onChange={handleInviteeChange}
                helperText=" "
                autoComplete="off"
              />

              {accessCard.map((arr, i) => {
                return (
                  <CustomFormControl
                    key={i}
                    variant="outlined"
                    className={"text-field"}
                    style={{ ...styles.input, ...styles.text }}
                  >
                    <Asynchronous
                      label="Location of visits"
                      name="class_name"
                      handleChange={(e) => numPlateChange(e, i)}
                      value={arr.accessCard}
                      dataPromise={access_card}
                      helperText=" "
                      required={i === 0}
                    />
                  </CustomFormControl>
                );
              })}
              <DateTimeField
                label={"Expire On"}
                value={expire_at}
                InputProps={{
                  endAdornment: CalendarIcon(),
                  style: { ...styles.text },
                }}
                className="a1-date-picker"
                helperText={""}
                onChange={(e) => handleVisitingTime(e)}
              />

              <SimpleTextField
                id="employer"
                name="employer"
                label="Employer"
                value={employer}
                onChange={handleInviteeChange}
                helperText=" "
                autoComplete="off"
              />
              {validationErrors && <Toast msg={validationErrors} />}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <SubmitButton loading={disableBtn} />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};
export default InvitePage;
