import React, { useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CustomDateTimePicker } from "./formField";
import DateFnsUtils from "@date-io/date-fns";
import { FaRegCalendarAlt } from "react-icons/fa";

const styles = {
  text: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    color: "rgb(64, 64, 64)",
  },
  input: {
    width: "99%",
    marginBottom: 10,
  },
};

const DateTimeField = ({ onChange, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const enableFuture = () => {
    let date = new Date();
    date.setDate(date.getDate() + 45);
    return date;
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomDateTimePicker
        required
        disablePast
        inputVariant="outlined"
        maxDate={enableFuture()}
        onChange={(date) => onChange(date)}
        style={{ ...styles.input }}
        format={"dd - MMM - yyyy hh:mm a"}
        InputLabelProps={{
          style: {
            color: "rgb(69, 27, 171)",
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: 300,
          },
        }}
        variant="outlined"
        className={`datetime-picker text-field`}
        {...rest}
        onOpen={() => {
          setIsOpen(true);
        }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onAccept={() => {
          setIsOpen(false);
        }}
        keyboardIcon={<FaRegCalendarAlt fill="#bdb9b9" />}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimeField;
