import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// import QRInvitations from "views/Invitation";
// import Contractors from "views/Contractor";
import Home from "pages/homePage";
import Invitation from "views/WebInvite";
// import Visit from "views/visit";
import AlertDetails from "./views/alertDetails";
// import Webcheckin from "views/Webcheckin";
// import Visitor from "views/Webcheckin/visitor";
// import Contractor from "views/Webcheckin/contractor";
// import Delivery from "views/Webcheckin/delivery";
// import PermissionDenied from "components/permissionDenied";
import { getCookie } from "helpers";
import { searchUnitData } from "api/visit";

const styles = {
  container: {
    backgroundColor: "#D5D5D5",
    minHeight: "100vh",
    padding: "12px",
  },
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      condoName: "",
      location: {},
      loader: true,
      aliasName: "Star Hub Green",
      errorPage: false,
      blockList: [],
      redirect: true,
      token: new URL(window.location.href).searchParams.get("token"),
    };
  }
  fetchBlock = () => {
    searchUnitData("", getCookie("vms_token"))
      .then(({ data }) => {
        let units = data.map((value) => ({
          label: value.unit_number,
          value: value.unit_number,
        }));
        this.setState({ blockList: units, loader: false });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loader: false, errorPage: true });
      });
  };
  render() {
    const { blockList, aliasName } = this.state;
    const about = { aliasName, blockList };

    return (
      <div style={styles.container}>
        <Router>
          <Switch>
            <Route
              path="/contractors/form"
              render={(props) => <Invitation {...props} />}
              exact
            />

            {/* <Route
              path="/invitations/:invitee_id"
              render={(props) => <QRInvitations {...props} />}
              exact
            /> */}
            {/* 
            <Route
              path="/contractor/:id"
              render={(props) => <Contractors {...props} />}
              exact
            />

            <Route
              path="/webcheckin"
              render={(props) => <Webcheckin {...props} />}
              exact
            />

            <Route
              path="/webcheckin/visitor"
              render={(props) => {
                if (redirect) {
                  this.setState({ redirect: false });
                  return <Redirect to={`/webcheckin/visitor`} />;
                }
                if (token) {
                  setCookie("vms_token", token, 60);
                  return <Visitor {...props} about={about} />;
                } else {
                  return <PermissionDenied {...props} />;
                }
              }}
              exact
            />

            <Route
              path="/webcheckin/contractor"
              render={(props) => {
                if (redirect) {
                  this.setState({ redirect: false });
                  return <Redirect to={`/webcheckin/contractor`} />;
                }
                if (token) {
                  setCookie("vms_token", token, 60);
                  return <Contractor {...props} about={about} />;
                } else {
                  return <PermissionDenied {...props} />;
                }
              }}
              exact
            />


            <Route
              path="/webcheckin/delivery"
              render={(props) => {
                if (redirect) {
                  this.setState({ redirect: false });
                  return <Redirect to={`/webcheckin/delivery`} />;
                }
                if (token) {
                  setCookie("vms_token", token, 60);
                  return <Delivery {...props} about={about} />;
                } else {
                  return <PermissionDenied {...props} />;
                }
              }}
              exact
            />

            <Route
              path="/visit-entry/:visit_id/details"
              render={(props) => <Visit {...props} />}
              exact
            /> */}

            <Route
              path="/alerts/:id/details"
              render={(props) => <AlertDetails {...props} />}
              exact
            />

            <Route
              path="/home"
              render={(props) => <Home {...props} about={about} />}
            />

            <Route to="*">
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
