import makeRequest from ".";

function generateParams(params) {
  let status = "";
  if (params) {
    status = `?status=${params || " "}`;
  }
  return status;
}

export function fetchContractorDetails(id) {
  return makeRequest({
    uri: `/api/v1/contractors/${id}`,
  });
}

export function fetchAccessPoints(status) {
  return makeRequest({
    uri: `/api/v1/access_points${generateParams(status)}`,
  });
}
