import React from "react";
import { Head, TicketBorder } from "components";
// import Lottie from "react-lottie";
import success from "assets/img/success.jpg";

const styles = {
  success: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    margin: 20,
  },
  content: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "30px",
    color: "#4A4A4A",
  },
  container1: {
    margin: "20px 25px 50px 25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const SuccessPage = () => {
  // const defaultOptions = {
  //   loop: false,
  //   autoplay: true,
  //   animationData: SmsSent,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  return (
    <React.Fragment>
      <Head subHeading={"Contractor Registration"} />
      <div className="main-container" style={{ padding: "0px" }}>
        <div style={{ textAlign: "center", width: "100%" }}>
          <p style={styles.success}>Registration Successfully</p>
          <img style={{ width: "84%" }} src={success} alt="" />
          {/* <Lottie options={defaultOptions} style={{ maxWidth: 300 }} /> */}
          {/* <div style={styles.container1}>
            <p style={styles.content}>
              An SMS is triggered to your visitors with a link for the Smart
              QR Code.
            </p>
            <p style={styles.content}>
              The Smart QR Code can be used for quick check-in at the main
              gates
            </p>
          </div> */}
        </div>
        <TicketBorder />
      </div>
    </React.Fragment>
  );
};

export default SuccessPage;
