import React, { Component } from "react";
import { createInvite } from "../../api/webInvite";
import InvitePage from "./invitePage";
import SuccessPage from "./successPage";
import { fetchAccessPoints } from "api/contractor";

class Invitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        phone: "",
        otp_token: "",
        name: "",
        fin_number: "",
        country_code: { label: "SG", value: "+65" },
        employer: "",
        visitor_type: "contractor",
        expire_at: null,
        remarks: "",
        accessCard: [{ accessCard: null }],
      },
      validation: {
        phone: false,
        fin_number: false,
      },
      inviteValidation: {
        phone: false,
        fin_number: false,
      },
      nricPrier: "XXXXX",
      auth_key: null,
      otpPage: false,
      invitePage: false,
      successPage: false,
      disableBtn: false,
      employee_id: null,
      access_card: [],
    };
  }

  componentDidMount = () => {
    this.accessNames();
  };

  handleChangeNric = (name, value, error) => {
    const { data, nricPrier, inviteValidation } = this.state;
    let { fin_number } = data;

    let fin = fin_number
      ? value.slice(0, 9)
      : `${nricPrier}${value.slice(0, 4)}`;
    this.setState({
      data: { ...data, fin_number: fin },
      inviteValidation: {
        ...inviteValidation,
        [name]: error,
      },
      validationErrors: null,
    });
  };

  handleInviteCustomChange = (name, value, valid) => {
    const { data, inviteValidation } = this.state;
    this.setState({
      data: { ...data, [name]: value },
      inviteValidation: {
        ...inviteValidation,
        [name]: valid,
      },
      validationErrors: null,
    });
  };

  handleVisitingTime = (value) => {
    const { data } = this.state;
    this.setState({
      data: { ...data, expire_at: value },
    });
  };

  handleInviteeChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    this.setState({ data: { ...data, [name]: value }, validationErrors: null });
  };

  inviteSubmit = () => {
    const { fin_number, accessCard, ...data } = this.state.data;
    const finNumber = fin_number.substr(5);
    const requestParams = {
      contractors: {
        name: data.name,
        fin_number: finNumber,
        employer: data.employer,
        phone: data.phone,
        status: "active",
        access_point_ids: accessCard.map((arr) => arr.accessCard),
        expire_at: data.expire_at,
        number_plate: data.number_plate,
        country_code: data.country_code.value,
      },
    };
    this.setState({ disableBtn: true });

    createInvite(requestParams)
      .then((data) => {
        this.setState({
          validationErrors: { success: [200, { message: data.message }] },
        });

        let display = data.auth_key ? "" : "successPage";
        this.setState({
          [display]: true,
          successPage: true,
          disableBtn: false,
          validationErrors: null,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          validationErrors: {
            err: err?.[1]?.data
              ? [err[0], { message: err?.[1]?.data[0] }]
              : err,
          },
          disableBtn: false,
        });
      });
  };

  accessNames = async () =>
    await fetchAccessPoints("active")
      .then(({ data }) => {
        this.setState({
          access_card: data.map((obj) => ({
            label: obj.name,
            value: obj.id,
          })),
        });
      })
      .catch((err) => {
        console.log(err);
      });

  numPlateChange = (event, index) => {
    let numPlate = this.state?.data.accessCard;

    if (index === numPlate.length - 1) {
      if (!numPlate[index].accessCard) {
        numPlate.push({
          accessCard: "",
        });
      }
    }

    if (event.target) {
      numPlate[index]["accessCard"] = event.target.value;
    }

    if (numPlate[index].accessCard) {
      this.setState({ accessCard: numPlate });
    } else {
      numPlate.length > 1 && numPlate.pop();
      this.setState({ accessCard: numPlate });
    }
  };

  render() {
    const {
      data,
      validationErrors,
      inviteValidation,
      successPage,
      access_card,
    } = this.state;

    const {
      inviteSubmit,
      handleInviteeChange,
      handleInviteCustomChange,
      handleVisitingTime,
      handleChangeNric,
      numPlateChange,
    } = this;

    if (successPage) {
      return <SuccessPage />;
    }

    return (
      <InvitePage
        handleInviteeChange={handleInviteeChange}
        handleInviteCustomChange={handleInviteCustomChange}
        data={data}
        validation={inviteValidation}
        inviteSubmit={inviteSubmit}
        validationErrors={validationErrors}
        handleVisitingTime={handleVisitingTime}
        handleChangeNric={handleChangeNric}
        access_card={access_card}
        numPlateChange={numPlateChange}
      />
    );
  }
}

export default Invitation;