import makeRequest from "./index";

export function verifyStaff(requestBody) {
  return makeRequest({
    uri: `/api/v1/staffs/send_otp`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function verifyOtp(requestBody) {
  return makeRequest({
    uri: `/api/v1/staffs/validate_otp`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function createInvite(requestBody) {
  return makeRequest({
    uri: "/api/v1/contractor_registrations",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}
