import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormControl, TextField } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import CountrySelect from "components/countrySelect";

export const styles = {
  input_min: {
    width: "100%",
  },
  otp_box: {
    width: "100%",
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "16px",
    color: "rgb(64, 64, 64)",
  },
  input: {
    flex: "1 1 59%",
  },
  inputText: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 20,
    color: "#404040",
  },
};

const wrapper = (Comp) =>
  withStyles({
    root: {
      " & .MuiInputBase-root": {
        borderRadius: "9px",
        borderColor: "rgba(0, 0, 0, 0.49)",
        backgroundColor: "#fff",
      },
      "& label.Mui-focused": {
        color: "#dedede",
        paddingRight: 20,
        marginLeft: 5,
        backgroundColor: "#fff",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#dedede",
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#dedede",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#dedede",
          borderWidth: 0.5,
        },
      },
    },
  })(Comp);

export const CustomTextField = wrapper(TextField);
export const CustomFormControl = wrapper(FormControl);
export const CustomDateTimePicker = wrapper(DateTimePicker);

export const SimpleTextField = (props) => {
  return (
    <CustomTextField
      variant="outlined"
      style={{ ...styles.input }}
      fullWidth
      InputLabelProps={{
        style: {
          color: "#451BAB",
          fontFamily: "Montserrat",
          fontSize: 14,
          fontWeight: 300,
        },
      }}
      inputProps={{
        // style: styles.inputText,
        autoComplete: "off",
      }}
      className="text-field"
      {...props}
    />
  );
};
export const SimpleDateField = () => {
  return <CustomDateTimePicker />;
};
export const SimplePhoneField = ({ onChange, error, ...restProps }) => {
  const handleChange = (e) => {
    const numberRegex = /^[0-9]*$/;
    let { name, value } = e.target;

    if (numberRegex.test(value)) {
      const error = value.length < 8;
      onChange(name, value, error);
    } else {
      return;
    }
  };
  return (
    <SimpleTextField
      autoComplete="off"
      label="phone"
      error={error}
      onChange={handleChange}
      helperText={error ? "Invalid Phone Number" : ""}
      inputProps={{
        maxLength: 11,
        minLength: 8,
        type: "tel",
      }}
      {...restProps}
    />
  );
};
export const SimpleOTPField = (props) => {
  return (
    <SimpleTextField
      inputProps={{
        maxLength: 4,
        inputMode: "tel",
        autoComplete: "off",
        style: {
          textAlign: "center",
          letterSpacing: "21px",
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: 20,
          color: "#4A4A4A",
        },
      }}
      style={{ ...styles.otp_box }}
      {...props}
    />
  );
};

export const CountryPhoneField = ({ selected, onSelect, ...phoneProps }) => {
  return (
    <div style={{ display: "flex", paddingBottom: "4%", gap: 10 }}>
      <CountrySelect selected={selected} onSelect={onSelect} />
      <SimplePhoneField {...phoneProps} />
    </div>
  );
};

export const SimpleNRICField = ({ value, onChange, error, ...restProps }) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    const error = value.length < 9;
    onChange(name, value, error);
  };
  return (
    <SimpleTextField
      value={value}
      onChange={handleChange}
      helperText={error ? "Invalid NRIC number" : " "}
      inputProps={{
        maxLength: 9,
        minLength: 9,
        autoComplete: "off",
      }}
      error={error}
      autoComplete="off"
      {...restProps}
    />
  );
};
